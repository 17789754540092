@import url('https://fonts.googleapis.com/css2?family=MedievalSharp&display=swap');

body{
  background-color: black;
  min-height: 100vh;
  /* height: fit-content; */
  /* overflow: hidden; */
  width: 100vw;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}
.body::-webkit-scrollbar {
  display: none;
}

.medieval{
  font-family: 'MedievalSharp', cursive;
}

.node{
  transition: scale 0.3s;
}
